import {
  CommonState,
  SectorData,
  SiteDirections,
  ViewJobState
} from "@/store/modules/common/interfaces";
import {
  COMMON_LOADING,
  GET_JOB_BY_ID,
  GET_ROLE_BY_NAME,
  GET_UNIQUE_SECTORS,
  NOTIFICATION_ACTION_LOADING,
  NOTIFICATIONS,
  NOTIFICATIONS_COUNT,
  NOTIFICATIONS_LOADING,
  ROLES,
  SITE_DIRECTION,
  VIEW_JOB_STATE,
  USER_INFO,
  SELECTED_USERS,
  APP_ASSESSMENTS,
  UPLOAD_FILE_CHUNK,
  USER_INFO_BY_EID_NAME,
  SELECTED_USER_BY_EID_NAME
} from "@/store/modules/common/constants";
import { Roles } from "@/interfaces/data_objects/roles";
import { clean_string } from "@/utils/global";
import { Jobs } from "@/interfaces/data_objects/jobs";
import { NotificationsResponse } from "@/interfaces/responses/notifications";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
export default {
  [COMMON_LOADING]: (state: CommonState): boolean => state.loading,
  [GET_UNIQUE_SECTORS]: (state: CommonState): SectorData[] => state.sectors,
  [ROLES]: (state: CommonState): Roles[] => state.roles,
  [USER_INFO]: (state: CommonState): SearchUserObject[] => state.users_info,
  [SELECTED_USERS]: (state: CommonState): SearchUserObject[] =>
    state.selected_users,
  [GET_JOB_BY_ID]: (state: CommonState): Jobs | null => state.searched_job,
  [GET_ROLE_BY_NAME]:
    (state: CommonState) =>
    (role_name: string): Roles | undefined => {
      const role = clean_string(role_name);
      return state.roles.find(
        (_role: Roles) => clean_string(_role.role_name) === role
      );
    },
  [VIEW_JOB_STATE]: (state: CommonState): ViewJobState => state.view_job_state,
  [NOTIFICATIONS_LOADING]: (state: CommonState): boolean =>
    state.notifications.loading,
  [NOTIFICATION_ACTION_LOADING]: (state: CommonState): boolean =>
    state.notifications.action_loading,
  [NOTIFICATIONS_COUNT]: (state: CommonState): number =>
    state.notifications.count,
  [NOTIFICATIONS]: (state: CommonState): NotificationsResponse[] =>
    state.notifications.notifications,
  [SITE_DIRECTION]: (state: CommonState): SiteDirections =>
    state.site_direction,
  [APP_ASSESSMENTS]: (state: CommonState): AppAssessments.Assessment[] =>
    state.app_assessments,
  // Get Upload file chunk loading
  [UPLOAD_FILE_CHUNK]: (state: CommonState): boolean =>
    state.upload_file_chunk_loading,
  [USER_INFO_BY_EID_NAME]: (state: CommonState): SearchUserObject[] =>
    state.user_info_by_eid_name,
  [SELECTED_USER_BY_EID_NAME]: (state: CommonState): SearchUserObject[] =>
    state.selected_users_by_eid_name
};
