import {
  NOTIFICATIONS,
  NOTIFICATIONS_LOADING
} from "@/store/modules/notifications/constants";
import {
  Notifications,
  NotificationState
} from "@/store/modules/notifications/interfaces";

export default {
  [NOTIFICATIONS_LOADING]: (state: NotificationState): boolean => state.loading,
  [NOTIFICATIONS]: (state: NotificationState): Notifications[] =>
    state.notifications
};
