import {
  GET_CANDIDATE_DATA,
  GET_ONBOARDING_LOADING,
  GET_RECRUITER_DATA
} from "@/store/modules/onboarding/constants";
import {
  CandidateData,
  OnboardingState,
  RecruiterData
} from "@/store/modules/onboarding/interfaces";

export default {
  [GET_RECRUITER_DATA]: (state: OnboardingState): RecruiterData =>
    state.recruiter_data,
  [GET_CANDIDATE_DATA]: (state: OnboardingState): CandidateData =>
    state.candidate_data,
  [GET_ONBOARDING_LOADING]: (state: OnboardingState): boolean => state.loading
};
