import getters from "@/store/modules/common/getters";
import mutations from "@/store/modules/common/mutations";
import actions from "@/store/modules/common/actions";
import {
  CommonState,
  SiteDirections,
  ViewJobState
} from "@/store/modules/common/interfaces";
export default {
  namespaced: true,
  state: (): CommonState => ({
    loading: false,
    sectors: [],
    roles: [],
    searched_job: null,
    users_info: [],
    selected_users: [],
    view_job_state: ViewJobState.DEFAULT,
    notifications: {
      loading: true,
      count: 0,
      notifications: [],
      action_loading: false
    },
    site_direction: SiteDirections.LTR,
    app_assessments: [],
    upload_file_chunk_loading: false,
    user_info_by_eid_name: [],
    selected_users_by_eid_name: []
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
