import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";
import { candidate_interview_routes } from "@/router/sub_routes/candidates/candidate_interview_routes";
import { candidate_assessment_routes } from "@/router/sub_routes/candidates/candidate_assessment_routes";
import { candidate_inception_assessment_routes } from "./sub_routes/candidates/candidate_inception_assessment_routes";

export const candidates_routes: RouteConfig = {
  path: "/candidate",
  component: () => import("@/views/candidate/CandidateWrapper.vue"),
  meta: {
    user_state: [UserState.CANDIDATE]
  },
  children: [
    {
      path: "",
      redirect: {
        name: "candidate-dashboard"
      }
    },
    {
      path: "dashboard",
      name: "candidate-dashboard",
      component: () =>
        import("@/views/candidate/InceptionCandidateDashboard.vue"),
      meta: {
        user_state: [UserState.CANDIDATE]
      }
    },
    {
      path: "jobs",
      component: () => import("@/views/candidate/CandidateJobsWrapper.vue"),
      meta: {
        user_state: []
      },
      children: [
        {
          path: "",
          name: "candidate-jobs",
          component: () => import("@/views/candidate/CandidateJobs.vue"),
          meta: {
            user_state: []
          }
        },
        {
          path: "view/:id",
          name: "candidate-matching-job",
          component: () => import("@/views/candidate/ViewMatchingJob.vue"),
          meta: {
            user_state: []
          }
        },
        {
          path: "upskill-career",
          name: "candidate-upskill-career",
          component: () => import("@/views/candidate/UpskillCareer.vue"),
          meta: {
            user_state: []
          }
        }
      ]
    },
    // candidate_interview_routes,
    {
      path: "courses",
      name: "candidate-courses",
      component: () => import("@/views/candidate/CandidateCourses.vue"),
      meta: {
        user_state: []
      }
    },
    {
      path: "profile",
      name: "personal-profile",
      component: () => import("@/views/candidate/ViewProfile.vue"),
      meta: {
        user_state: [UserState.CANDIDATE]
      }
    },
    {
      path: "profile-loading",
      name: "profile-fetching",
      component: () => import("@/views/candidate/ProfileLoading.vue"),
      meta: {
        user_state: [UserState.CANDIDATE_PROFILE_PENDING]
      }
    },
    // candidate_assessment_routes,
    candidate_inception_assessment_routes,
    {
      path: "error",
      name: "candidate-error",
      component: () => import("@/views/ErrorPage.vue"),
      meta: {
        user_state: [UserState.CANDIDATE]
      }
    },
    {
      path: "*",
      component: () => import("../views/NotFound.vue"),
      meta: {
        user_state: [
          UserState.UNAUTHENTICATED,
          UserState.EMAIL_VERIFICATION_PENDING,
          UserState.EMAIL_VERIFICATION_COMPLETED,
          UserState.ONBOARDING_PENDING,
          UserState.RECRUITER,
          UserState.RECRUITER_ADMIN,
          UserState.CANDIDATE
        ]
      }
    }
  ]
};
