import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const recruiter_employees: RouteConfig = {
  path: "employees",
  component: () => import("@/views/recruiter/RecruiterEmployees.vue"),
  meta: {
    user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
  },
  children: [
    {
      path: "",
      name: "recruiter-view-employees",
      component: () => import("@/views/recruiter/employees/ViewEmployees.vue"),
      meta: {
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    }
  ]
};
