import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import onboarding from "@/store/modules/onboarding";
import common from "@/store/modules/common";
import candidate from "@/store/modules/candidates";
import recruiter from "@/store/modules/recruiter";
import { RootState } from "@/store/modules/root/interfaces";
import root_mutations from "@/store/modules/root/mutations";
import root_actions from "@/store/modules/root/actions";
import root_getters from "@/store/modules/root/getters";
import notifications from "@/store/modules/notifications";
import recruiter_admin from "@/store/modules/recruiter_admin";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    onboarding,
    common,
    candidate,
    recruiter,
    notifications,
    recruiter_admin
  },

  state: (): RootState => ({
    root_loading: false,
    root_error: null,
    root_notification: null
  }),
  mutations: root_mutations,
  actions: root_actions,
  getters: root_getters
});
