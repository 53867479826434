import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const recruiter_candidates: RouteConfig = {
  path: "candidates",
  component: () => import("@/views/recruiter/RecruiterCandidates.vue"),
  meta: {
    user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
  },
  children: [
    {
      path: "",
      name: "recruiter-view-candidates",
      component: () =>
        import("@/views/recruiter/candidates/ViewCandidates.vue"),
      meta: {
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    },
    {
      path: "profile",
      name: "candidate-profile",
      component: () =>
        import("@/views/recruiter/candidates/ViewCandidateProfile.vue"),
      meta: {
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    }
  ]
};
