import {
  RESET_CANDIDATE_DATA,
  RESET_RECRUITER_DATA,
  SET_CANDIDATE_DATA,
  SET_ONBOARDING_LOADING,
  SET_RECRUITER_DATA
} from "@/store/modules/onboarding/constants";
import {
  OnboardingState,
  SetCandidateDataPayload,
  SetRecruiterDataPayload
} from "@/store/modules/onboarding/interfaces";

export default {
  [SET_ONBOARDING_LOADING]: (
    state: OnboardingState,
    payload: boolean
  ): void => {
    state.loading = payload;
  },
  [SET_RECRUITER_DATA]: (
    state: OnboardingState,
    payload: SetRecruiterDataPayload
  ): void => {
    const obj_name = payload.obj_name as keyof typeof state.recruiter_data;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.recruiter_data[obj_name] = payload.value;
  },
  [SET_CANDIDATE_DATA]: (
    state: OnboardingState,
    payload: SetCandidateDataPayload
  ): void => {
    const obj_name = payload.obj_name as keyof typeof state.candidate_data;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.candidate_data[obj_name] = payload.value;
  },
  [RESET_RECRUITER_DATA]: (state: OnboardingState): void => {
    state.recruiter_data = {
      company_name: null,
      company_avatar: null,
      company_size: null,
      company_industry: null,
      company_location: null,
      company_website: null,
      company_description: null
    };
  },
  [RESET_CANDIDATE_DATA]: (state: OnboardingState): void => {
    state.candidate_data = {
      candidate_avatar: null,
      candidate_resume: null,
      candidate_social_links: [],
      candidate_availability: []
    };
  }
};
