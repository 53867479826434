import { Jobs, JobsWithSkillsGroup } from "@/interfaces/data_objects/jobs";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { SearchJobObject } from "@/interfaces/shared/jobs/auto_complete_job_search";
import { Interview } from "@/interfaces/responses/interviews/interviews";
import { AssessmentsListingResponse } from "@/interfaces/responses/assessments/assessments";
import { SocialLinks } from "../onboarding/interfaces";
import { ZappyhireCandidate } from "@/interfaces/data_objects/user";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import { AppAssessmentNames } from "../common/interfaces";

export interface RecruiterState {
  loading: boolean;
  jobs_posted_loading: boolean;
  poke_candidate_loading: boolean;
  view_candidates_dynamically: ViewCandidatesDynamically;
  add_standardised_job: JobsWithSkillsGroup | null;
  edited_Standardised_job: boolean;
  jobs_posted: {
    pagination: number;
    total: number;
    results: JobsWithCandidates[];
    date_filter: string[];
    searched_job_title: SearchAutocompleteJobPayload | null;
  };
  top_jobs_posted: {
    total: number;
    results: JobsWithCandidates[];
  };
  recommend_job_profiles: Jobs[];
  // When a recruiter wants to view a matching candidate, this payload will contain job_id & cv_id
  // Job id and cv id is required to view candidate profile
  candidate_view_payload: CandidateViewPayload | null;
  all_candidates: AllCandidates;
  post_job_limit_error: string;
  interviews: Interviews;
  view_candidates_pagination: ViewCandidatesPagination;
  assessment_listing: AssessmentListing;
  eng_assessment_listing: AppEngAssessmentListing;
  annotation_assessment_listing: AppAnnotationAssessmentListing;
  eng_assessment_listing_v2: AppEngAssessmentListing;
  annotation_assessment_listing_v2: AppAnnotationAssessmentListing;
  active_assessment: ActiveAssessment;
  inseption_stats: InceptionStats;
  overall_assessment_listing: AppOverAllAssessmentListing;
}

export interface InceptionStats {
  users: string;
  englishAssessment: string;
  annotationAssessment: string;
}
export interface AssessmentListing {
  fetch_loading: boolean;
  pagination: number;
  selected_dates: string[];
  data: AssessmentsListingResponse.Root;
}
export interface ViewCandidatesPagination {
  pagination: number;
}
export interface ViewCandidatesDynamically {
  active: boolean;
  loading: boolean;
  job_id: number | null;
  job_title: string;
  skill: string;
  user_ids: [];
}
export interface JobsWithCandidates extends Jobs {
  matching_candidates: SearchedCandidate[];
  candidates_loading: boolean;
}

export interface PostJobPayload {
  job_title: string;
  job_description: string;
  job_industry: string;
  job_type: string;
  job_experience: string;
  skills_list: string;
  is_standardize: boolean;
  edited: boolean;
}

export interface GetJobsPostedPayload {
  page?: number;
  limit_per_page?: number;
  dashboard?: boolean;
  is_standardize?: boolean;
  job_title?: string;
  date_filter?: string[];
}
export interface SetJobsMutationPayload {
  total: number;
  results: JobsWithCandidates[];
}

export interface MatchingCandidatesByIdPayload {
  job_id: number;
  n?: number;
  dashboard?: boolean;
  skill_name?: string;
  page?: number;
  limit_per_page?: number;
  user_ids?: number[];
}

export interface UpdateMatchingCandidatePayload {
  job_id: number;
  candidates: null | Candidates.Candidates[];
}

export interface UpdateJobPayload {
  job_id: number;
  job_title: string;
  job_description: string;
  job_sector: string;
  job_experience: string;
  job_employment_type: string;
  job_skills_list: string;
}
export interface CandidateViewPayload {
  job_id: number;
  cv_id: number;
  upload_response_id: string;
}

export interface MembersJobsStatistics {
  filter: string;
  start_date: string;
  end_date: string;
}

export interface UpdateFavoriteCandidatePayload {
  is_favorite: boolean;
  candidate_id: number;
  job_id: number;
}

export interface AllCandidates {
  total: number;
  results: Candidates.Candidates[];
  filtered_count: number;
}

// Interface for poke candidate request payload
export interface PokeCandidatePayload {
  job_id: number; // Job jid
  candidate_id: number; // User pk
  type?: string;
}

// Interface for saving updated job description
export interface SaveUpdatedJobDescriptionPayload {
  job_id: number;
  description: string;
  sector: string;
}

// For start interview API POST call payload
export interface StartInterviewPayload {
  candidate_id: number;
  interview_id: number;
}

// For resume interview API POST call payload
export interface ResumeInterviewPayload {
  interview_id: number;
  answer?: string;
}
// For storing interview variables
export interface Interviews {
  search_job_loading: boolean;
  searched_job: SearchJobObject | null;
  pagination: number;
  interview_listing: Map<number, InterviewListingResult>;
  selected_candidate: InterviewListingCandidates[];
}

export interface InterviewListing {
  [id: string]: InterviewListingResult;
}

export interface InterviewListingResult {
  id: number;
  title: number;
  date: string;
  candidates: InterviewListingCandidates[];
  zappy_job_id?: number;
}

export interface InterviewListingCandidates {
  avatar_uri: string;
  first_name: string;
  interview_status: string;
  last_name: string;
  id: number;
  class: string;
  readable_status: string;
  interview_by: number;
  interview_id: number;
  candidate_by: number;
  job_id: number;
  content?: string;
  candidate_id?: number;
  zappy_job_id?: number;
}
export interface InterviewListingPayload {
  page?: number;
  limit?: number;
  interviewed_by?: number;
  job_id?: number;
  candidate_id?: number;
  interview_id?: number;
}

export interface UpdateInterviewStatusPayload {
  interview_id: number;
  action: Interview.Status;
  recording?: File;
  filename?: string;
}

export interface InterviewHistoryData {
  [key: number]: string;
}

export interface IndividualInterviewHistory {
  candidate_interview_history: string;
  name: string;
  avatar?: string;
  candidate_id?: number;
  zappy_job_id?: number;
  job_id: number;
}

export interface InterviewReport {
  [key: string]: InterviewReportDetails;
}
export interface InterviewReportDetails {
  rating: number;
  reason: string;
  color: string;
  bg_color: string;
  icon: string;
  key: string;
}

export interface FetchAssessmentListingPayload {
  page: number;
  limit: number;
  selected_searched_user: number[];
  date_picker_field: string[] | null;
}

export interface SearchAutocompleteJobPayload {
  job_id: number;
  text: string;
}

export interface SearchedCandidate {
  job_id?: number;
  score?: number;
  candidate_id: number;
  upload_response_id: string;
  upload_response: {
    profile?: SearchedCandidateProfile;
  };
  avatar_uri: string;
  is_favorite?: boolean;
  social_links: SocialLinks[];
  availability: string[];
  status: string;
  is_account_confirmed?: boolean;
  last_name?: string;
  first_name?: string;
  eid?: number;
  email?: string;
  entity_location?: string;
}
export interface SearchedCandidateProfile {
  personal_details: {
    name: {
      first_name: string;
      middle_name: string;
      last_name: string;
    };
  };
  work_history_detailed?: {
    [key: string]: {
      job_title: string;
    };
  };
  total_experience?: string;
}

export interface SearchCandidatesPayload {
  job_ids?: number[];
  n?: number;
  dashboard?: boolean;
  skill_name?: string;
  page?: number;
  limit_per_page?: number;
  user_ids?: number[];
  registered?: boolean;
  approved?: boolean;
}

export interface SearchCandidatesAccumulator {
  [key: number]: SearchedCandidate[];
}

export interface UpdateMatchingCandiadteMutationPayload {
  candidates_job_based: SearchCandidatesAccumulator;
}

export interface ZappyhireCreateCandidatePayload {
  job_id: number;
  payload: ZappyhireCandidate;
  candidate_id: number;
}

export interface ZapphirePostJobResponse {
  status: number;
  errors: string;
  results: {
    job_id?: number;
  };
}

export interface ZapphireCreateCandidateResponse {
  results?: {
    candidate_id?: number;
  };
}

export interface UpdateZappyInterviewListingObjectPayload {
  job_id: number;
  zappy_job_id: number;
}

export interface AppEngAssessmentListing {
  page: number;
  limit: number;
  total: number;
  results: AppAssessments.AssessmentResults[];
  selected_dates: DateFilterPayload;
  selected_candidates: SearchUserObject[];
  score_filter?: string;
  status_filter?: string;
  user?: AppAssessments.AssessmentUserObject;
}

export interface AppAnnotationAssessmentListing {
  page: number;
  limit: number;
  total: number;
  results: AppAssessments.AnnotationAssessmentResults[];
  selected_dates: DateFilterPayload;
  selected_candidates: SearchUserObject[];
  score_filter?: string;
  status_filter?: string;
  user?: AppAssessments.AssessmentUserObject;
}
export interface AppAssessmentListingPayload {
  page: number;
  limit: number;
  assessment_id: number;
  selected_dates: DateFilterPayload;
  selected_candidates: SearchUserObject[];
  version?: string;
  score_filter?: string;
  status_filter?: string;
}

export interface FetchAssessmentResult {
  result_id: number;
  assessment_id: number;
}

export interface ActiveAssessment {
  assessment_id: number | null;
  assessment_type: string;
}

export interface DateFilterPayload {
  date_picker_menu: boolean;
  date_range_value: string;
  date_filter: string[];
}

export interface DeleteAssessmentPayload {
  user_id: number;
  assessment_id: number;
}

export enum CompanySettingTypes {
  ASSESSMENT_SETTING = "assessment_setting"
}

export interface UpdateCompanySettingPayload {
  type: CompanySettingTypes;
  current_assessment_type: AppAssessments.CurrentAssessmentType;
  current_assessment: AppAssessmentNames;
}
export enum AssessmentVersions {
  VERSION_1 = "v1",
  VERSION_2 = "v2"
}

export interface AppOverAllAssessmentListing {
  page: number;
  limit: number;
  total: number;
  results: AppOverAllResult[];
  selected_dates: DateFilterPayload;
  selected_candidates: SearchUserObject[];
  score_filter: string;
  eid_name_filter: string;
  average_score_filter: number[];
  english_assessment_filter: number[];
  annotation_assessment_filter: number[];
  assessment_version: AssessmentVersions;
  download_report?: boolean;
}

export interface AppOverAllResult {
  user_id: number | null;
  english_assessment: AppAssessments.AssessmentResult | null;
  annotation_assessment: AppAssessments.AnnotationAssessmentResult | null;
  user: AppAssessments.AssessmentUserObject | null;
  average_score: string;
  date: string;
  annotation_assessment_id: number | null;
  english_assessment_id: number | null;
}
