



















import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { ROOT_NOTIFICATION } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "NotificationSnackbar",
  data() {
    return {
      notification_snackbar: true
    };
  },
  created() {
    setTimeout(this.reset_notification, 6000);
  },
  computed: {
    ...mapGetters({
      root_notification: ROOT_NOTIFICATION
    })
  },
  methods: {
    ...mapMutations({
      set_root_notification: ROOT_NOTIFICATION
    }),
    reset_notification() {
      this.notification_snackbar = false;
      this.set_root_notification(null);
    }
  }
});
