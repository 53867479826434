import {
  AL_AIN_USER_FILTER,
  GET_ALL_CANDIDATES,
  RESET_RECRUITER_ADMIN_STATE,
  VIEW_CANDIDATE_DATA,
  VIEW_CANDIDATE_FILTER
} from "@/store/modules/recruiter_admin/constants";
import {
  AllCandidates,
  RecruiterAdmin
} from "@/store/modules/recruiter_admin/interfaces";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";
import { SearchedCandidate } from "../recruiter/interfaces";
export default {
  [GET_ALL_CANDIDATES]: (
    state: RecruiterAdmin,
    payload: AllCandidates
  ): void => {
    state.candidates = {
      ...state.candidates,
      total: payload.total,
      results: payload.results
    };
  },
  [VIEW_CANDIDATE_DATA]: (
    state: RecruiterAdmin,
    payload: SearchedCandidate | null
  ): void => {
    state.view_candidate_data = payload;
  },
  [VIEW_CANDIDATE_FILTER]: (
    state: RecruiterAdmin,
    payload: ViewCandidatesOptions
  ): void => {
    state.view_candidate_filter = payload;
  },
  [RESET_RECRUITER_ADMIN_STATE]: (state: RecruiterAdmin): void => {
    state.view_candidate_data = null;
    state.candidates = {
      total: 0,
      results: [],
      pagination: 1
    };
    state.view_candidate_filter = ViewCandidatesOptions.REGISTERED;
    state.al_ain_users = true;
  },
  [AL_AIN_USER_FILTER]: (state: RecruiterAdmin, payload: boolean): void => {
    state.al_ain_users = payload;
  }
};
