export const GET_CANDIDATE_LOADING = "GET_CANDIDATE_LOADING";
export const SET_CANDIDATE_LOADING = "SET_CANDIDATE_LOADING";

export const GET_JOBS_LOADING = "GET_JOBS_LOADING";
export const SET_JOBS_LOADING = "SET_JOBS_LOADING";

export const GET_MATCHING_JOBS = "GET_MATCHING_JOBS";
export const SET_MATCHING_JOBS = "SET_MATCHING_JOBS";

export const GET_MATCHING_JOB_BY_ID = "GET_MATCHING_JOB_BY_ID";

export const UPSKILL_COURSES = "UPSKILL_COURSES";
export const UPSKILL_COURSES_LOADING = "UPSKILL_COURSES_LOADING";

export const GET_TOP_MATCHING_JOB = "GET_TOP_MATCHING_JOB";

// For holding the job id to upskill course
export const JOB_ID_FOR_UPSKILL = "JOB_ID_FOR_UPSKILL";

// For initializing startup data
export const INIT_CANDIDATE = "INIT_CANDIDATE";

// For getting job match against job id
export const GET_JOB_MATCH_AGAINST_JOB_ID = "GET_JOB_MATCH_AGAINST_JOB_ID";

// For holding searched matching jobs
export const SEARCHED_MATCHING_JOBS = "SEARCHED_MATCHING_JOBS";

// For reset state to initial
export const RESET_CANDIDATE_STATE = "RESET_CANDIDATE_STATE";

export const SEARCH_ANY_JOB = "SEARCH_ANY_JOB";

// For career path job fetching
export const CAREER_PATH = "CAREER_PATH";

// For toggle job fav status
export const TOGGLE_JOB_FAV_STATUS = "TOGGLE_JOB_FAV_STATUS";
export const TOGGLE_JOB_FAV_STATUS_LOADING = "TOGGLE_JOB_FAV_STATUS_LOADING";

// For getting fav jobs
export const GET_FAV_JOBS = "GET_FAV_JOBS ";

// For updating fav jobs status in store
export const UPDATE_FAV_JOBS_STATUS = "UPDATE_FAV_JOBS_STATUS";

export const CANDIDATE_INTERVIEW_LISTING = "CANDIDATE_INTERVIEW_LISTING";
export const CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING =
  "CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING";

export const REGISTER_TENANT = "REGISTER_TENANT";
export const CHECK_TENANT_EXISTENCE = "CHECK_TENANT_EXISTENCE";

export const TENANT_INFO_LOADING = "TENANT_INFO_LOADING";

export const TENANT_INFO_DETAILS = "TENANT_INFO_DETAILS";

export const TENANT_INFO = "TENANT_INFO";

export const ASSESSMENT_QUESTIONS = "ASSESSMENT_QUESTIONS";

export const ASSESSMENT_RECORD = "ASSESSMENT_RECORD";

export const QUESTION_INDEX = "QUESTION_INDEX";

export const GET_CURRENT_QUESTION = "GET_CURRENT_QUESTION ";

export const UPDATE_ASSESSMENT_RECORD = "UPDATE_ASSESSMENT_RECORD";

export const SUBMIT_ASSESSMENT_ANSWERS = "SUBMIT_ASSESSMENT_ANSWERS";

export const CURRENT_QUESTION_INDEX = "CURRENT_QUESTION_INDEX";

export const ASSESSMENT_ERROR = "ASSESSMENT_ERROR";

export const INTERVIEW_LISTING_PAGINATION = "INTERVIEW_LISTING_PAGINATION";
export const CANDIDATE_INTERVIEWS = "CANDIDATE_INTERVIEWS";

// Candidate jobs
export const CANDIDATE_JOBS = "CANDIDATE_JOBS";
export const TOP_MATCHING_JOBS = "TOP_MATCHING_JOBS";
export const MATCHING_JOBS = "MATCHING_JOBS";
export const MATCHING_JOBS_LOADING = "MATCHING_JOBS_LOADING";
export const MATCHING_JOBS_TITLE = "MATCHING_JOBS_TITLE";

// App Assessments
export const ASSESSMENT_QUESTIONNAIRES = "ASSESSMENT_QUESTIONNAIRES";
export const ASSESSMENT_QUESTIONNAIRES_ENGLISH =
  "ASSESSMENT_QUESTIONNAIRES_ENGLISH";

export const ASSESSMENT_QUESTIONNAIRES_ENGLISH_UPDATE =
  "ASSESSMENT_QUESTIONNAIRES_UPDATE";

export const SUBMIT_APP_ASSESSMENT_ANSWERS = "SUBMIT_APP_ASSESSMENT_ANSWERS";
export const SUBMIT_APP_ASSESSMENT_ANSWER_MEDIA =
  "SUBMIT_APP_ASSESSMENT_ANSWER_MEDIA";

// For annotation Assessment
export const ASSESSMENT_QUESTIONNAIRES_ANNOTATION =
  "ASSESSMENT_QUESTIONNAIRES_ANNOTATION";

export const ASSESSMENT_QUESTIONNAIRES_ANNOTATION_UPDATE =
  "ASSESSMENT_QUESTIONNAIRES_ANNOTATION_UPDATE";

export const SUBMIT_APP_ASSESSMENT_MEDIA = "SUBMIT_APP_ASSESSMENT_MEDIA";

export const APP_ENG_ASSESSMENT_CANCELLED = "APP_ENG_ASSESSMENT_CANCELLED";
export const APP_ENG_ASSESSMENT_COMPLETED = "APP_ENG_ASSESSMENT_COMPLETED";
export const APP_ENG_CURRENT_QUESTION_INDEX = "APP_ENG_CURRENT_QUESTION_INDEX";
export const APP_ASSESSMENT_SS = "APP_ASSESSMENT_SS";

export const APP_ANNOTATION_ASSESSMENT_CANCELLED =
  "APP_ANNOTATION_ASSESSMENT_CANCELLED";
export const APP_ANNOTATION_ASSESSMENT_COMPLETED =
  "APP_ANNOTATION_ASSESSMENT_COMPLETED";
export const APP_ANNOTATION_CURRENT_QUESTION_INDEX =
  "APP_ANNOTATION_CURRENT_QUESTION_INDEX";

export const SCREEN_RECORDING_SUPPORTED = "SCREEN_RECORDING_SUPPORTED";
export const CAMERA_RECORDING_SUPPORTED = "CAMERA_RECORDING_SUPPORTED";
export const ENG_ASSESSMENT_COMPLETED = "ENG_ASSESSMENT_COMPLETED";
export const ENG_ASSESSMENT_CANCELLED = "ENG_ASSESSMENT_CANCELLED";
export const ANNOTATION_ASSESSMENT_COMPLETED =
  "ANNOTATION_ASSESSMENT_COMPLETED";
export const ANNOTATION_ASSESSMENT_CANCELLED =
  "ANNOTATION_ASSESSMENT_CANCELLED";

export const SCREEN_RECORDING_PERMISSION_GRANTED =
  "SCREEN_RECORDING_PERMISSION_GRANTED";
export const CAMERA_RECORDING_PERMISSION_GRANTED =
  "CAMERA_RECORDING_PERMISSION_GRANTED";
