import {
  AllCandidates,
  AssessmentListing,
  CandidateViewPayload,
  InterviewListingResult,
  Interviews,
  JobsWithCandidates,
  RecruiterState,
  SearchAutocompleteJobPayload,
  ViewCandidatesDynamically,
  ViewCandidatesPagination,
  InterviewListingCandidates,
  AppEngAssessmentListing,
  AppAnnotationAssessmentListing,
  ActiveAssessment,
  InceptionStats,
  AppOverAllAssessmentListing
} from "@/store/modules/recruiter/interfaces";
import {
  RECRUITER_LOADING,
  JOBS_POSTED_BY_RECRUITER,
  JOBS_POSTED_BY_RECRUITER_LOADING,
  TOP_JOBS_WITH_CANDIDATES,
  VIEW_CANDIDATES_DYNAMICALLY,
  FETCH_MATCHING_CANDIDATES,
  RECOMMEND_JOB_PROFILES,
  CANDIDATE_VIEW_PAYLOAD,
  ALL_CANDIDATES,
  POKE_CANDIDATE_LOADING,
  POST_JOB_LIMIT_ERROR,
  HOLD_STANDARDISED_JOB,
  INTERVIEW_SEARCHED_JOB,
  INTERVIEW_LISTING,
  RECRUITER_INTERVIEW_SEARCH_JOB_LOADING,
  VIEW_CANDIDATES_PAGINATION,
  INTERVIEW_OBJECT,
  EDITED_STANDARDISED_JOB,
  ASSESSMENT_LISTING,
  ASSESSMENT_LISTING_DATES_FILTER,
  SELECTED_CANDIDATE_INTERVIEW_REPORT,
  JOB_LISTING_DATE_FILTER,
  JOB_LISTING_SEARCHED_JOB_FILTER,
  GET_INTERVIEW_LISTING_OBJECT,
  APP_ENG_ASSESSMENT_LISTING,
  APP_ANNOTATION_ASSESSMENT_LISTING,
  ACTIVE_ASSESSMENT,
  INCEPTION_STATS,
  FETCH_OVERALL_ASSESSMENTS
} from "@/store/modules/recruiter/constants";
import { Jobs, JobsWithSkillsGroup } from "@/interfaces/data_objects/jobs";
import { SearchJobObject } from "@/interfaces/shared/jobs/auto_complete_job_search";

export default {
  [RECRUITER_LOADING]: (state: RecruiterState): boolean => state.loading,
  [JOBS_POSTED_BY_RECRUITER_LOADING]: (state: RecruiterState): boolean =>
    state.jobs_posted_loading,
  [JOBS_POSTED_BY_RECRUITER]: (
    state: RecruiterState
  ): { total: number; results: JobsWithCandidates[] } => state.jobs_posted,
  [TOP_JOBS_WITH_CANDIDATES]: (
    state: RecruiterState
  ): { total: number; results: JobsWithCandidates[] } => state.top_jobs_posted,
  [VIEW_CANDIDATES_DYNAMICALLY]: (
    state: RecruiterState
  ): ViewCandidatesDynamically => state.view_candidates_dynamically,
  [FETCH_MATCHING_CANDIDATES]:
    (state: RecruiterState) =>
    (job_id: number, top_jobs: false): JobsWithCandidates | undefined => {
      if (top_jobs)
        return state.top_jobs_posted.results.find(
          (v: Jobs) => v.jid === job_id
        );
      else return state.jobs_posted.results.find((v: Jobs) => v.jid === job_id);
    },
  [RECOMMEND_JOB_PROFILES]: (state: RecruiterState): Jobs[] =>
    state.recommend_job_profiles,
  [CANDIDATE_VIEW_PAYLOAD]: (
    state: RecruiterState
  ): CandidateViewPayload | null => state.candidate_view_payload,
  [ALL_CANDIDATES]: (state: RecruiterState): AllCandidates =>
    state.all_candidates,
  /**
   * Returning poke candidate loading
   * @return boolean
   */
  [POKE_CANDIDATE_LOADING]: (state: RecruiterState): boolean =>
    state.poke_candidate_loading,
  [POST_JOB_LIMIT_ERROR]: (state: RecruiterState): string =>
    state.post_job_limit_error,
  [HOLD_STANDARDISED_JOB]: (
    state: RecruiterState
  ): JobsWithSkillsGroup | null => state.add_standardised_job,
  /**
   * Return searched job for fetching all interviewers of specific job
   * @param state
   */
  [INTERVIEW_SEARCHED_JOB]: (state: RecruiterState): SearchJobObject | null =>
    state.interviews.searched_job,
  [INTERVIEW_LISTING]: (
    state: RecruiterState
  ): Map<number, InterviewListingResult> | null =>
    state.interviews.interview_listing,
  [RECRUITER_INTERVIEW_SEARCH_JOB_LOADING]: (state: RecruiterState): boolean =>
    state.interviews.search_job_loading,
  [VIEW_CANDIDATES_PAGINATION]: (
    state: RecruiterState
  ): ViewCandidatesPagination => state.view_candidates_pagination,
  [INTERVIEW_OBJECT]: (state: RecruiterState): Interviews => state.interviews,
  [EDITED_STANDARDISED_JOB]: (state: RecruiterState): boolean =>
    state.edited_Standardised_job,
  [ASSESSMENT_LISTING]: (state: RecruiterState): AssessmentListing =>
    state.assessment_listing,
  [ASSESSMENT_LISTING_DATES_FILTER]: (state: RecruiterState): string[] =>
    state.assessment_listing.selected_dates,
  [SELECTED_CANDIDATE_INTERVIEW_REPORT]: (
    state: RecruiterState
  ): InterviewListingCandidates[] => state.interviews.selected_candidate,
  [JOB_LISTING_DATE_FILTER]: (state: RecruiterState): string[] =>
    state.jobs_posted.date_filter,
  [JOB_LISTING_SEARCHED_JOB_FILTER]: (
    state: RecruiterState
  ): SearchAutocompleteJobPayload | null =>
    state.jobs_posted.searched_job_title,
  [GET_INTERVIEW_LISTING_OBJECT]:
    (state: RecruiterState) =>
    (job_id: number): InterviewListingResult | undefined => {
      return state.interviews.interview_listing.get(job_id);
    },
  // English Assessment listing
  [APP_ENG_ASSESSMENT_LISTING]:
    (state: RecruiterState) =>
    (version: string): AppEngAssessmentListing => {
      if (version === "v1") return state.eng_assessment_listing;
      else return state.eng_assessment_listing_v2;
    },
  // Annotation Assessment listing
  [APP_ANNOTATION_ASSESSMENT_LISTING]:
    (state: RecruiterState) =>
    (version: string): AppAnnotationAssessmentListing => {
      if (version === "v1") return state.annotation_assessment_listing;
      else return state.annotation_assessment_listing_v2;
    },
  [ACTIVE_ASSESSMENT]: (state: RecruiterState): ActiveAssessment =>
    state.active_assessment,
  [INCEPTION_STATS]: (state: RecruiterState): InceptionStats =>
    state.inseption_stats,
  [FETCH_OVERALL_ASSESSMENTS]: (
    state: RecruiterState
  ): AppOverAllAssessmentListing => state.overall_assessment_listing
};
