export const GET_ALL_CANDIDATES = "GET_ALL_CANDIDATES";

export const ACTION_CANDIDATE = "ACTION_CANDIDATE";

export const VIEW_CANDIDATE_DATA = "VIEW_CANDIDATE_DATA";

export const VIEW_CANDIDATE_FILTER = "VIEW_CANDIDATE_FILTER";

export const RESET_RECRUITER_ADMIN_STATE = "RESET_RECRUITER_ADMIN_STATE";
export const AL_AIN_USER_FILTER = "AL_AIN_USER_FILTER";
