import getters from "@/store/modules/onboarding/getters";
import mutations from "@/store/modules/onboarding/mutations";
import actions from "@/store/modules/onboarding/actions";
import { OnboardingState } from "@/store/modules/onboarding/interfaces";
export default {
  namespaced: true,
  state: (): OnboardingState => ({
    loading: false,
    recruiter_data: {
      company_name: null,
      company_avatar: null,
      company_size: null,
      company_industry: null,
      company_location: null,
      company_website: null,
      company_description: null
    },
    candidate_data: {
      candidate_avatar: null,
      candidate_resume: null,
      candidate_social_links: [],
      candidate_availability: []
    }
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
