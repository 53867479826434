import {
  ActionCandidatePayload,
  GetAllCandidatesPayload
} from "@/store/modules/recruiter_admin/interfaces";
import axios, { AxiosResponse } from "axios";
import {
  CANDIDATE_ACTION,
  GET_ALL_CANDIDATES
} from "@/store/urls/recruiter_admin";

export const get_all_candidates = async (
  payload: GetAllCandidatesPayload
): Promise<AxiosResponse> => {
  return axios.post(GET_ALL_CANDIDATES, payload);
};

export const action_candidate = async (
  payload: ActionCandidatePayload
): Promise<AxiosResponse> => {
  return axios.post(CANDIDATE_ACTION, payload);
};
