



















import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import RootLoader from "@/components/shared/RootLoader.vue";
import {
  ROOT_ERROR,
  ROOT_LOADING,
  ROOT_NOTIFICATION
} from "@/store/modules/root/constants";
import ErrorSnackbar from "@/components/shared/snackbar/ErrorSnackbar.vue";
import NotificationSnackbar from "@/components/shared/snackbar/NotificationSnackbar.vue";
import { POST_JOB_LIMIT_ERROR } from "@/store/modules/recruiter/constants";
import PostJobLimitError from "@/components/shared/snackbar/PostJobLimitError.vue";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import store from "@/store";
import { LanguageKey, SiteDirections } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "App",
  components: {
    PostJobLimitError,
    NotificationSnackbar,
    ErrorSnackbar,
    RootLoader
  },
  async created() {
    this.set_root_loading(true);
    // If token not exists and route isn't login
    // Navigate to log in
    // if (!this.get_user_token && this.$route.name !== "login")
    //   await router.push("/");
    const local_local = localStorage.getItem("locale");
    if (
      local_local === LanguageKey.ENGLISH ||
      local_local === LanguageKey.ARABIC
    ) {
      store.commit(
        `common/${SITE_DIRECTION}`,
        local_local === LanguageKey.ENGLISH
          ? SiteDirections.LTR
          : SiteDirections.RTL
      );
    } else {
      store.commit(`common/${SITE_DIRECTION}`, SiteDirections.LTR);
    }

    this.set_root_loading(false);
  },
  computed: {
    ...mapGetters("recruiter", {
      get_post_job_limit_error: POST_JOB_LIMIT_ERROR
    }),
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION,
      root_loading: ROOT_LOADING
    })
  },
  methods: {
    ...mapMutations({
      set_root_loading: ROOT_LOADING,
      set_root_error: ROOT_ERROR
    })
  }
});
