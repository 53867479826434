import getters from "@/store/modules/candidates/getters";
import mutations from "@/store/modules/candidates/mutations";
import actions from "@/store/modules/candidates/actions";
import { CandidateState } from "@/store/modules/candidates/interfaces";
export default {
  namespaced: true,
  state: (): CandidateState => ({
    loading: false,
    jobs_loading: false,
    matching_jobs: [],
    upskill_courses: null,
    upskill_courses_loading: false,
    toggle_job_fav_status_loading: false,
    job_id_for_upskill: null,
    searched_matching_jobs: {
      jobs: [],
      title: "",
      active: false
    },
    interviews: {
      search_job_loading: false,
      listing: null,
      pagination: 1
    },
    tenant_info: {
      error: false,
      loading: false,
      details: null,
      total_questions: 100,
      progress_value: 0,
      assessment_questions: {
        questions: [],
        assessmentProgress: {
          shortscaleComplete: false,
          assessmentComplete: false,
          fractionComplete: 0
        }
      },
      assessment_record: [],
      question_index: 0
    },
    candidate_jobs: {
      // For candidate jobs page
      matching_jobs: {
        loading: false,
        results: [],
        total: 0,
        page: 1,
        limit: 12
      },
      // For candidate dashboard
      top_matching_jobs: {
        loading: false,
        results: [],
        total: 0
      }
    },
    app_assessments: {
      english: null,
      annotation: null,
      ss: []
    },
    screen_recording_supported: true,
    camera_recording_supported: true,
    annotation_assessment_cancelled: false,
    annotation_assessment_completed: false,
    camera_recording_permission_granted: false,
    screen_recording_permission_granted: false,
    eng_assessment_cancelled: false,
    eng_assessment_completed: false
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
