import {
  ROOT_ERROR,
  ROOT_LOADING,
  ROOT_NOTIFICATION
} from "@/store/modules/root/constants";
import { RootState } from "@/store/modules/root/interfaces";

export default {
  [ROOT_LOADING]: (state: RootState): boolean => state.root_loading,
  [ROOT_NOTIFICATION]: (state: RootState): string | null =>
    state.root_notification,
  [ROOT_ERROR]: (state: RootState): string | null => state.root_error
};
