import axios, { AxiosResponse } from "axios";
import {
  CHECK_TENANT_EXISTENCE,
  FETCH_ASSESSMENT_QUESTIONS,
  FETCH_CAREER_PATH,
  FETCH_FAV_JOBS,
  GET_MATCHING_JOBS,
  REGISTER_TENANT,
  SEARCH_WHOLE_SYSTEM_JOBS,
  SKILL_GAP_AGAINST_JOB,
  SUBMIT_APP_ASSESSMENT_ANSWERS,
  SUBMIT_APP_ASSESSMENT_MEDIA,
  SUBMIT_ASSESSMENT_ANSWERS,
  TOGGLE_JOB_FAV_STATUS,
  UPSKILL_COURSES
} from "@/store/urls/candidate";
import {
  CareerPathPayload,
  GetMatchingJobsPayload,
  RegisterTenantPayload,
  SearchAnyJobPayload,
  SubmitAppAssessmentMediaPayload,
  SubmitAssessmentAnswersApiPayload,
  SubmitAssessmentAnswersPayload,
  ToggleJobFavStatusPayload,
  UpskillCoursesActionPayload
} from "@/store/modules/candidates/interfaces";
import { GetJobMatchActionPayload } from "@/store/modules/common/interfaces";
import { APP_ASSESSMENTS } from "../urls/common";

export const get_matching_jobs = async (
  payload: GetMatchingJobsPayload
): Promise<AxiosResponse> => {
  let query = `${GET_MATCHING_JOBS}?page=${payload.page}&limit=${payload.limit}&candidate_id=${payload.candidate_id}`;
  if (payload.job_title) query += `&job_title=${payload.job_title}`;

  return await axios.get(query).then((response) => response.data);
};

export const upskill_courses = async (
  payload: UpskillCoursesActionPayload
): Promise<AxiosResponse> => {
  return await axios.post(UPSKILL_COURSES, payload);
};

/**
 * Function to get job match against job id
 * @param payload
 */
export const skill_gap_against_job = async (
  payload: GetJobMatchActionPayload
): Promise<AxiosResponse> => await axios.post(SKILL_GAP_AGAINST_JOB, payload);

export const search_whole_system_jobs = async (
  payload: SearchAnyJobPayload
): Promise<AxiosResponse> =>
  await axios.post(SEARCH_WHOLE_SYSTEM_JOBS, payload);

/**
 * Function to fetch career path
 * @param {CareerPathPayload} payload: Career path to fetch against upload_response_id
 */
export const fetch_career_path = async (
  payload: CareerPathPayload
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_CAREER_PATH, payload);
};

/**
 * Function to toggle job fav status
 * @param {ToggleJobFavStatusPayload} payload
 */
export const toggle_job_fav_status = async (
  payload: ToggleJobFavStatusPayload
): Promise<AxiosResponse> => {
  return await axios.post(`${TOGGLE_JOB_FAV_STATUS}${payload.action}`, {
    job_id: payload.job_id
  });
};

/**
 * Function to fetch fav jobs of candidate
 * @param job_ids
 */
export const fetch_fav_jobs = async (
  job_ids: number[]
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_FAV_JOBS, { job_ids });
};

export const register_tenant = async (
  payload: RegisterTenantPayload
): Promise<AxiosResponse> => {
  return await axios.post(REGISTER_TENANT, payload);
};

export const check_tenant_existence = async (): Promise<AxiosResponse> => {
  return await axios.get(CHECK_TENANT_EXISTENCE);
};

export const fetch_assessment_questions = async (): Promise<AxiosResponse> => {
  return await axios.get(FETCH_ASSESSMENT_QUESTIONS);
};

export const submit_assessment_answers = async (
  payload: SubmitAssessmentAnswersPayload
): Promise<AxiosResponse> => {
  return await axios.post(SUBMIT_ASSESSMENT_ANSWERS, payload);
};

export const fetch_assessment_questionnaries_by_id = async (
  assessment_id: number
): Promise<AxiosResponse> => {
  return await axios.get(`${APP_ASSESSMENTS}${assessment_id}/questionnaire`);
};

export const submit_assessment_answers_by_id = async (
  payload: SubmitAssessmentAnswersApiPayload
): Promise<AxiosResponse> => {
  const { assessment_id, ...rest } = payload;
  return await axios.post(
    `${SUBMIT_APP_ASSESSMENT_ANSWERS}${assessment_id}/answers`,
    rest
  );
};

export const submit_assessment_answer_media = async (
  payload: FormData
): Promise<AxiosResponse> => {
  const assessment_id = payload.get("assessment_id");
  const answer_id = payload.get("answer_id");
  payload.delete("assessment_id");
  payload.delete("answer_id");
  return await axios.post(
    `${SUBMIT_APP_ASSESSMENT_ANSWERS}${assessment_id}/answer/${answer_id}/media`,
    payload
  );
};

export const submit_app_assessment_media = async (
  payload: SubmitAppAssessmentMediaPayload
): Promise<AxiosResponse> => {
  const { data, assessment_id } = payload;
  return await axios.post(
    `${SUBMIT_APP_ASSESSMENT_MEDIA}${assessment_id}/media`,
    data
  );
};
