










import Vue from "vue";

export default Vue.extend({
  name: "RootLoader",
  data() {
    return {
      loading_animation: require("@/assets/illustrations/loading.json")
    };
  },
  created() {
    this.loading_animation = JSON.stringify(this.loading_animation);
  }
});
