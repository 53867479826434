export namespace CareerPath {
  export interface Info {
    resume_skill_count: number;
    n: number;
    job_data_count: number;
  }

  export interface TopJob {
    jid: number;
    external_job_id: string | null;
    job_title: string;
    sector: string;
    industry: string;
    matching_score: number;
    job_match: string[];
    job_gap: string[];
    norm_score: number;
    career_path: string[];
  }
  export interface Root {
    info: Info;
    top_jobs: TopJob[];
  }
}

export const default_career_path = {
  top_jobs: [
    {
      jid: 5397,
      external_job_id: null,
      job_title: "Marketing Executive",
      sector: "Transport, Tourism and Accommodation Services",
      industry: "Transport, Tourism and Accommodation Services",
      matching_score: 0.2413793103448276,
      job_match: [
        "Content Marketing",
        "Marketing Channel",
        "Digital Marketing",
        "Marketing Strategies",
        "Marketing Communications",
        "Systems Thinking",
        "Business Marketing",
        "Web Design",
        "Advertising Campaigns",
        "Brand Marketing",
        "Marketing Management",
        "Word-of-Mouth Marketing",
        "Marketing Research",
        "Marketing Effectiveness",
        "Employment Marketing",
        "Management Systems",
        "Marketing Analysis",
        "Inbound Marketing",
        "Process Management",
        "Certified Marketing Executive",
        "Relationship Marketing"
      ],
      job_gap: [
        "Business Planning",
        "Learning Development",
        "Key Performance Indicators (KPIs)",
        "Technology Integration",
        "Relational Data Mining",
        "Interpersonal Communications",
        "Performance Analysis",
        "Computing Platforms",
        "Creative Thinking",
        "Customer Acquisition Management",
        "Portfolio Management",
        "Customer Relationship Management",
        "Performance Management",
        "Market Trend",
        "Innovation",
        "Customer Experience",
        "Stakeholder Management",
        "Hand Tools",
        "Digital Literacy",
        "Corporate Communications",
        "Market Research",
        "Business Performance Management",
        "Performance Review",
        "Design Thinking",
        "Business Analysis",
        "Social Media",
        "Receiving Procedures",
        "Customer Relationship Building",
        "Organizational Branding",
        "New Product Development",
        "Political Campaigns",
        "Relationship Management",
        "Brand Awareness",
        "promote recreation activities",
        "Service Level",
        "Research",
        "Analytics",
        "Media Management",
        "Technology Readiness Level",
        "Solution Design",
        "Performance Improvement",
        "Conflict Management",
        "Purchasing Management",
        "Data Mining",
        "Brand Communication",
        "Resource Management",
        "Campaign Advertising",
        "Business Development",
        "Project Implementation",
        "Vendor Management",
        "Market Development",
        "Business Presentations",
        "Campaign Management",
        "Social Media Management",
        "Resource Leveling",
        "Continuous Improvement Process",
        "Change Management",
        "Literacy",
        "Presentation Delivery",
        "Communication Planning",
        "High-Potential Development",
        "Innovation Management",
        "Branding",
        "Management Effectiveness",
        "Industry Analysis",
        "Service Level Management"
      ],
      norm_score: 0.12209302325581395,
      career_path: [
        "Software Engineer",
        "Cloud Engineer",
        "Sr. Software Engineer",
        "Software Architect",
        "Project Manager"
      ]
    }
  ],
  info: {
    resume_skill_count: 27,
    n: 3,
    job_data_count: 1783
  }
};
