import { NotificationState } from "@/store/modules/notifications/interfaces";
import getters from "@/store/modules/notifications/getters";
import mutations from "@/store/modules/notifications/mutations";
import actions from "@/store/modules/notifications/actions";

export default {
  namespaced: true,
  state: (): NotificationState => ({
    notifications: [],
    loading: false
  }),

  getters: getters,
  mutations: mutations,
  actions: actions
};
