import { AuthState, UserState } from "@/store/modules/auth/interfaces";
import getters from "@/store/modules/auth/getters";
import mutations from "@/store/modules/auth/mutations";
import actions from "@/store/modules/auth/actions";

export default {
  namespaced: true,
  state: (): AuthState => ({
    loading: false,
    auth_error: null,
    user: null,
    company: null,
    user_profile: null,
    user_state: UserState.UNAUTHENTICATED,
    reset_password_payload: null,
    email_for_reset_password: null
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
